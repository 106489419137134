<template>
  <v-row>
    <v-col cols="12">
      <CompanyProfile />
    </v-col>

    <v-col cols="12">
      <Users />
    </v-col>
  </v-row>
</template>

<script>
import CompanyProfile from '@/components/admin/partials/Company/Detail/Info/CompanyProfile'
import Users from '@/components/admin/partials/Company/Detail/Info/Users'

export default {
  name: 'index',
  components: { Users, CompanyProfile },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
