var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',{staticClass:"px-0"},[_c('v-container',{staticClass:"px-0 py-0 mb-2"},[_c('v-row',{staticClass:"px-8",attrs:{"align":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"label text-capitalize font-weight-regular",attrs:{"height":"29","depressed":"","color":"secondary","ripple":false}},[_vm._v(" "+_vm._s(_vm.$t('page_company_profile_title'))+" ")])],1),_c('v-col',{staticClass:"pl-0",attrs:{"cols":"auto"}},[_c('h5',{staticClass:"text-date font-weight-regular mb-0"},[_vm._v(" "+_vm._s(_vm.getCompany.created_at)+" ")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('h5',{staticClass:"text-date font-weight-regular mb-0"},[_vm._v(" "+_vm._s(_vm.$t('by', { person: 'Admin' }))+" ")])])],1)],1)],1),_c('v-divider'),_c('validation-observer',{ref:"observer"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_vm._l((_vm.fieldAreas),function(fieldArea,index){return _c('div',{key:'area-' + index},[_c('v-card-text',[_c('v-container',[_c('v-row',{attrs:{"align":"center"}},_vm._l((fieldArea),function(field){return _c('v-col',{key:field.name,attrs:{"cols":"6"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"text-right input-label",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(field.label)+" ")]),(field.type === 'label')?_c('v-col',{staticClass:"text-label",class:field.color ? field.color + '--text' : '',attrs:{"cols":"4"}},[(field.value === null)?[_vm._v(" -- ")]:(field.name === 'current_business_year')?[_vm._v(" "+_vm._s(_vm.$t('year_label', { number: field.value.term, start: field.value.start_date, end: field.value.end_date }))+" ")]:[_vm._v(" "+_vm._s(field.value)+" ")]],2):_vm._e(),(
                      field.type === 'monthselect' ||
                        field.type === 'dayselect'
                    )?_c('v-col',{attrs:{"cols":field.additional_text ? '4' : '8'}},[_c('validation-provider',{attrs:{"name":field.name,"rules":field.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{attrs:{"outlined":"","dense":"","flat":"","error-messages":errors,"item-text":field.type === 'monthselect' ? 'name' : 'text',"item-value":field.type === 'monthselect' ? 'id' : 'value',"return-object":field.type === 'monthselect',"items":field.type === 'monthselect'
                            ? _vm.months
                            : _vm.generateDays(field.value.days),"disabled":_vm.loading},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})]}}],null,true)})],1):_vm._e(),(field.type === 'select')?_c('v-col',{attrs:{"cols":field.additional_text ? '4' : '8'}},[_c('validation-provider',{attrs:{"name":field.name,"rules":field.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-select',{attrs:{"outlined":"","dense":"","flat":"","error-messages":errors,"item-text":"name","item-value":"id","items":_vm.getSelectItems(field.name),"disabled":_vm.loading},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})]}}],null,true)})],1):_vm._e(),(
                      field.type === 'text' ||
                        field.type === 'tel' ||
                        field.type === 'number'
                    )?_c('v-col',{attrs:{"cols":field.additional_text ? '4' : '8'}},[_c('validation-provider',{attrs:{"name":field.name,"rules":field.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","flat":"","error-messages":errors,"hide-details":!errors,"disabled":_vm.loading,"type":field.type},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})]}}],null,true)})],1):_vm._e(),(field.type === 'datepicker')?_c('v-col',{attrs:{"cols":field.additional_text ? '4' : '8'}},[_c('v-dialog',{ref:"monthPickerModal",refInFor:true,attrs:{"return-value":field.value,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(field, "value", $event)},"update:return-value":function($event){return _vm.$set(field, "value", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":field.name,"rules":field.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":"","outlined":"","dense":"","error-messages":errors,"flat":"","disabled":_vm.loading},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.monthPickerModal),callback:function ($$v) {_vm.monthPickerModal=$$v},expression:"monthPickerModal"}},[_c('v-date-picker',{attrs:{"type":"date","scrollable":""},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.monthPickerModal = false}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.monthPickerModal[index - 1].save(
                              field.value
                            )}}},[_vm._v(" "+_vm._s(_vm.$t('ok'))+" ")])],1)],1)],1):_vm._e(),(field.additional_text)?_c('v-col',{staticClass:"input-label",attrs:{"cols":"4"}},[(field.additional_text.type === 'dayselect')?[_c('validation-provider',{attrs:{"name":field.additional_text.name,"rules":field.additional_text.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-select',{attrs:{"outlined":"","dense":"","flat":"","error-messages":errors,"items":field.value !== undefined && field.value !== null
                              ? _vm.generateDays(field.value.days)
                              : [],"disabled":_vm.loading ||
                              field.value === undefined ||
                              field.value === null},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                              var item = ref.item;
return [_vm._v(" "+_vm._s(item)+_vm._s(_vm.$t('day'))+" ")]}},{key:"item",fn:function(ref){
                              var item = ref.item;
return [_vm._v(" "+_vm._s(item)+_vm._s(_vm.$t('day'))+" ")]}}],null,true),model:{value:(field.additional_text.value),callback:function ($$v) {_vm.$set(field.additional_text, "value", $$v)},expression:"field.additional_text.value"}})]}}],null,true)})]:[_vm._v(" "+_vm._s(((field.additional_text.text) + " " + (field.additional_text.value)))+" ")]],2):_vm._e()],1)],1)}),1)],1)],1),(index !== _vm.fieldAreas.length - 1)?_c('v-divider'):_vm._e()],1)}),_c('v-card-actions',[_c('v-row',{staticClass:"flex-row-reverse"},[_c('v-col',{staticClass:"text-right",attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"type":"submit","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('update'))+" ")])],1)],1)],1)],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }