<template>
  <v-card :loading="loading">
    <v-card-title class="px-0">
      <v-container class="px-0 py-0 mb-2">
        <v-row align="end" class="px-8">
          <v-col cols="auto">
            <v-btn
              height="29"
              class="label text-capitalize font-weight-regular"
              depressed
              color="secondary"
              :ripple="false"
            >
              {{ $t('page_company_profile_title') }}
            </v-btn>
          </v-col>

          <v-col cols="auto" class="pl-0">
            <h5 class="text-date font-weight-regular mb-0">
              {{ getCompany.created_at }}
            </h5>
          </v-col>

          <v-col cols="auto">
            <h5 class="text-date font-weight-regular mb-0">
              {{ $t('by', { person: 'Admin' }) }}
            </h5>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>

    <v-divider></v-divider>

    <validation-observer ref="observer">
      <form @submit.prevent="submit">
        <div v-for="(fieldArea, index) in fieldAreas" :key="'area-' + index">
          <v-card-text>
            <v-container>
              <v-row align="center">
                <v-col v-for="field in fieldArea" :key="field.name" cols="6">
                  <v-row align="center">
                    <v-col cols="2" class="text-right input-label">
                      {{ field.label }}
                    </v-col>

                    <v-col
                      v-if="field.type === 'label'"
                      cols="4"
                      class="text-label"
                      :class="field.color ? field.color + '--text' : ''"
                    >
                      <template v-if="field.value === null">
                        --
                      </template>
                      <template
                        v-else-if="field.name === 'current_business_year'"
                      >
                        {{
                          $t('year_label', {
                            number: field.value.term,
                            start: field.value.start_date,
                            end: field.value.end_date
                          })
                        }}
                      </template>
                      <template v-else>
                        {{ field.value }}
                      </template>
                    </v-col>

                    <v-col
                      v-if="
                        field.type === 'monthselect' ||
                          field.type === 'dayselect'
                      "
                      :cols="field.additional_text ? '4' : '8'"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="field.name"
                        :rules="field.rules"
                      >
                        <v-select
                          outlined
                          dense
                          flat
                          :error-messages="errors"
                          :item-text="
                            field.type === 'monthselect' ? 'name' : 'text'
                          "
                          :item-value="
                            field.type === 'monthselect' ? 'id' : 'value'
                          "
                          :return-object="field.type === 'monthselect'"
                          :items="
                            field.type === 'monthselect'
                              ? months
                              : generateDays(field.value.days)
                          "
                          v-model="field.value"
                          :disabled="loading"
                        >
                        </v-select>
                      </validation-provider>
                    </v-col>

                    <v-col
                      v-if="field.type === 'select'"
                      :cols="field.additional_text ? '4' : '8'"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="field.name"
                        :rules="field.rules"
                      >
                        <v-select
                          outlined
                          dense
                          flat
                          :error-messages="errors"
                          item-text="name"
                          item-value="id"
                          v-model="field.value"
                          :items="getSelectItems(field.name)"
                          :disabled="loading"
                        >
                        </v-select>
                      </validation-provider>
                    </v-col>

                    <v-col
                      v-if="
                        field.type === 'text' ||
                          field.type === 'tel' ||
                          field.type === 'number'
                      "
                      :cols="field.additional_text ? '4' : '8'"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="field.name"
                        :rules="field.rules"
                      >
                        <v-text-field
                          outlined
                          dense
                          flat
                          :error-messages="errors"
                          :hide-details="!errors"
                          v-model="field.value"
                          :disabled="loading"
                          :type="field.type"
                        >
                        </v-text-field>
                      </validation-provider>
                    </v-col>

                    <v-col
                      v-if="field.type === 'datepicker'"
                      :cols="field.additional_text ? '4' : '8'"
                    >
                      <v-dialog
                        ref="monthPickerModal"
                        v-model="monthPickerModal"
                        :return-value.sync="field.value"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <validation-provider
                            v-slot="{ errors }"
                            :name="field.name"
                            :rules="field.rules"
                          >
                            <v-text-field
                              v-model="field.value"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              outlined
                              dense
                              :error-messages="errors"
                              flat
                              :disabled="loading"
                            ></v-text-field>
                          </validation-provider>
                        </template>
                        <v-date-picker
                          v-model="field.value"
                          type="date"
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="monthPickerModal = false"
                          >
                            {{ $t('cancel') }}
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="
                              $refs.monthPickerModal[index - 1].save(
                                field.value
                              )
                            "
                          >
                            {{ $t('ok') }}
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>

                    <v-col
                      v-if="field.additional_text"
                      cols="4"
                      class="input-label"
                    >
                      <template
                        v-if="field.additional_text.type === 'dayselect'"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          :name="field.additional_text.name"
                          :rules="field.additional_text.rules"
                        >
                          <v-select
                            outlined
                            dense
                            flat
                            :error-messages="errors"
                            :items="
                              field.value !== undefined && field.value !== null
                                ? generateDays(field.value.days)
                                : []
                            "
                            v-model="field.additional_text.value"
                            :disabled="
                              loading ||
                                field.value === undefined ||
                                field.value === null
                            "
                          >
                            <template v-slot:selection="{ item }">
                              {{ item }}{{ $t('day') }}
                            </template>
                            <template v-slot:item="{ item }">
                              {{ item }}{{ $t('day') }}
                            </template>
                          </v-select>
                        </validation-provider>
                      </template>
                      <template v-else>
                        {{
                          `${field.additional_text.text} ${field.additional_text.value}`
                        }}
                      </template>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-divider v-if="index !== fieldAreas.length - 1"></v-divider>
        </div>
        <v-card-actions>
          <v-row class="flex-row-reverse">
            <v-col cols="2" class="text-right">
              <v-btn type="submit" color="primary" class="text-capitalize">
                {{ $t('update') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </form>
    </validation-observer>
  </v-card>
</template>

<script>
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
export default {
  name: 'CompanyProfile',
  data() {
    return {
      loading: false,
      monthPickerModal: false,
      fieldAreas: [
        [
          {
            name: 'id',
            label: this.$t('page_company_profile_field_id'),
            type: 'label',
            value: '',
            color: 'primary'
          },
          {
            name: 'postcode',
            label: this.$t('page_company_profile_field_postal_code'),
            type: 'number',
            value: '',
            rules: 'numeric'
          },
          {
            name: 'status',
            label: this.$t('page_company_profile_field_status'),
            type: 'select',
            value: '',
            rules: 'required',
            additional_text: {
              name: 'last_updated_date',
              text: this.$t('page_company_profile_field_last_updated_date'),
              value: ''
            }
          },
          {
            name: 'prefecture',
            label: this.$t('page_company_profile_field_prefecture'),
            type: 'select',
            value: '',
            rules: ''
          },
          {
            name: 'freee_name',
            label: this.$t('page_company_profile_field_freee_name'),
            type: 'text',
            value: '',
            rules: ''
          },
          {
            name: 'city',
            label: this.$t('page_company_profile_field_city'),
            type: 'text',
            value: '',
            rules: ''
          },
          {
            name: 'display_name',
            label: this.$t('page_company_profile_field_display_name'),
            type: 'text',
            value: '',
            rules: 'required'
          },
          {
            name: 'address',
            label: this.$t('page_company_profile_field_address'),
            type: 'text',
            value: '',
            rules: ''
          },
          {
            name: 'subscription',
            label: this.$t('page_company_profile_field_plan'),
            type: 'select',
            value: '',
            rules: 'required'
          },
          {
            name: 'phone',
            label: this.$t('page_company_profile_field_phone'),
            type: 'number',
            value: '',
            rules: 'numeric'
          },
          {
            name: 'contact_name',
            label: this.$t('page_company_profile_field_owner_name'),
            type: 'text',
            value: '',
            rules: ''
          },
          {
            name: 'email',
            label: this.$t('page_company_profile_field_email'),
            type: 'text',
            value: '',
            rules: 'email'
          },
          {
            name: 'connected_date',
            label: this.$t('page_company_profile_field_member_from'),
            type: 'label',
            value: ''
          }
        ],
        [
          {
            name: 'type',
            label: this.$t('page_company_profile_field_business_type'),
            type: 'select',
            value: '',
            rules: ''
          },
          {
            name: 'registration_date',
            label: this.$t('page_company_profile_field_registration_date'),
            type: 'datepicker',
            value: '',
            rules: 'required'
          },
          {
            name: 'business_year_start_month',
            label: this.$t('page_company_profile_field_business_year_month_start'),
            type: 'monthselect',
            value: null,
            rules: 'required',
            additional_text: {
              name: 'business_year_start_day',
              label: this.$t('page_company_profile_field_business_year_day_start'),
              type: 'dayselect',
              value: '',
              rules: 'required'
            }
          },
          // {
          //   name: "business_year_end_month",
          //   label: "End month of Business Year",
          //   type: "monthselect",
          //   value: null,
          //   rules: "required",
          //   additional_text: {
          //     name: "business_year_end_day",
          //     label: "End date of the month",
          //     type: "dayselect",
          //     value: "",
          //     rules: "required"
          //   }
          // },
          {
            name: 'head_count',
            label: this.$t('page_company_profile_field_number_of_employee'),
            type: 'select',
            value: '',
            rules: ''
          },
          {
            name: 'current_business_year',
            label: this.$t('page_company_profile_field_current_term'),
            type: 'label',
            value: ''
          }
        ]
      ]
    }
  },
  computed: {
    ...mapGetters([
      'getCompany',
      'getPrefectures',
      'getSubscriptionPlans',
      'getCompanyTypes',
      'getHeadCounts',
      'getCompanyStatuses'
    ]),
    months() {
      let months = []
      for (let i = 1; i <= 12; i++) {
        months.push({
          id: i,
          name: `${i}${this.$t('month')}`,
          days: dayjs(`2021-${i}-1`).daysInMonth()
        })
      }
      return months
    }
  },
  created() {
    this.fillFields()
  },
  methods: {
    getDataFromApi() {
      this.loading = true
    },
    fillFields() {
      let company = this.getCompany
      this.loading = true
      for (const fieldArea of this.fieldAreas) {
        for (const field of fieldArea) {
          if (field.additional_text) {
            field.additional_text.value = company[field.additional_text.name]
          }

          if (field.type === 'select') {
            if (company[field.name] !== null)
              field.value = company[field.name]?.id
            continue
          }

          if (field.type === 'number' && company[field.name] !== null) {
            field.value = company[field.name]
            if (company[field.name].indexOf('-') > -1) {
              field.value = company[field.name].split('-').join('')
            }
            continue
          }

          if (field.name === 'business_year_start_month') {
            if (company.business_year_start?.month) {
              field.value = this.months.find(
                month => month.id === company.business_year_start.month
              )
              field.additional_text.value = company.business_year_start.day
            }
            continue
          }

          field.value = company[field.name]
        }
      }
      this.loading = false
    },
    getSelectItems(field) {
      switch (field) {
        case 'subscription':
          return this.getSubscriptionPlans
        case 'prefecture':
          return this.getPrefectures
        case 'status':
          return this.getCompanyStatuses
        case 'type':
          return this.getCompanyTypes
        case 'head_count':
          return this.getHeadCounts
        default:
          return []
      }
    },

    generateDays(days) {
      return [...Array(days + 1).keys()].slice(1)
    },

    submit() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return
        }

        let form = {}

        for (const fieldArea of this.fieldAreas) {
          for (const field of fieldArea) {
            if (field.type === 'label' && field.name !== 'id') continue

            if (field.value === null || field.value === undefined) continue

            if (field.additional_text) {
              form[field.additional_text.name] = field.additional_text.value
            }

            if (field.type === 'select') {
              let fieldName = `${field.name}_id`

              if (field.name === 'subscription') {
                fieldName = `${field.name}_plan_id`
              }

              form[fieldName] = field.value
            }

            if (field.type === 'monthselect') {
              if (field.name === 'business_year_start_month') {
                form.business_year_start_month = field.value.id
                continue
              }
            }

            form[field.name] = field.value
          }
        }

        this.$store
          .dispatch('COMPANY_UPDATE', { id: form.id, data: form })
          .then(() => {
            this.getDataFromApi()
          })

        this.$nextTick(() => {
          this.$refs.observer.reset()
        })
      })
    }
  }
}
</script>

<style lang="scss" src="./CompanyProfile.scss" scoped></style>
