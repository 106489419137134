<template>
  <v-card>
    <v-card-title class="px-0 pb-2">
      <v-container class="px-0 py-0 mb-2">
        <v-row align="end" class="px-8">
          <v-col cols="auto">
            <v-btn
              height="29"
              class="label text-capitalize font-weight-regular"
              depressed
              color="secondary"
              :ripple="false"
            >
              <v-icon left>$users</v-icon>
              {{ $t('page_company_users_title') }}
            </v-btn>
          </v-col>

          <v-col cols="auto" class="pl-0">
            <h5 class="text-date font-weight-regular mb-0">
              {{ getCompany.created_at }}
            </h5>
          </v-col>

          <v-spacer></v-spacer>

          <v-col cols="auto">
            <v-btn text color="primary" class="text-capitalize" :to="{ name: 'UserCreate' }">
              <v-icon>mdi-plus</v-icon>
              {{ $t('page_company_users_add_user_button') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>

    <v-divider class="mb-2"></v-divider>

    <v-card-text class="px-0">
      <Table
        :headers="headers"
        :items="getCompanyUsers"
        :footer="false"
        :number-of-pages="1"
        :total-records="getCompanyUsers.length"
      >
        <template v-slot:item.id="{ item }">
          <div class="primary--text">{{ item.id }}</div>
        </template>

        <template v-slot:item.avatar>
          <v-avatar color="grey" size="36" class="white--text">
            {{ $t('av') }}
          </v-avatar>
        </template>

        <template v-slot:item.last_name="{ item }">
          <div>{{ item.last_name }} {{ item.first_name }}</div>
          <div class="text-furigana-fade">
            {{ `${item.last_name_kana} ${item.first_name_kana}` }}
          </div>
        </template>

        <template v-slot:item.action="{ item }">
          <v-btn color="secondary" icon :to="`/users/${item.id}/edit`">
            <v-icon size="16">$edit</v-icon>
          </v-btn>
        </template>
      </Table>
      <v-divider></v-divider>
    </v-card-text>
  </v-card>
</template>

<script>
import Table from '@/components/admin/partials/Table/Table'
import { mapGetters } from 'vuex'

export default {
  name: 'Users',
  components: { Table },
  created() {
    this.loading = true
    this.$store
      .dispatch('COMPANY_USERS_GET', { id: this.$route.params.id })
      .finally(() => (this.loading = false))
    this.loading = false
  },
  computed: {
    ...mapGetters(['getCompany', 'getCompanyUsers'])
  },
  data() {
    return {
      loading: false,
      users: [],
      headers: [
        { text: this.$t('id'), align: 'center', value: 'id', sortable: false },
        { text: '', align: 'center', value: 'avatar', sortable: false },
        { text: this.$t('page_company_users_table_header_name'), align: 'center', value: 'last_name' },
        { text: this.$t('page_company_users_table_header_position'), align: 'center', value: 'position' },
        { text: this.$t('page_company_users_table_header_email'), align: 'center', value: 'email' },
        { text: this.$t('page_company_users_table_header_registered'), align: 'center', value: 'created_at' },
        { text: this.$t('actions'), align: 'center', value: 'action', sortable: false }
      ],
      search: ''
    }
  }
}
</script>

<style lang="scss" src="./Users.scss" scoped></style>
